import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import logo from '../images/weingut-goeschl-logo.png'
import facebook from '../images/facebook.svg'
import instagram from '../images/instagram.svg'

const Container = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;
  margin-bottom: 20px;
  width: 100%;
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  @media (max-width: 760px) {
    align-items: flex-start;
  }
`

const Logo = styled.img`
  width: 92px;
  height: auto;
  @media (max-width: 760px) {
    width: 98px;
  }
`

const List = styled.ul`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
  @media (max-width: 760px) {
    /* flex-direction: column; */
    text-align: right;
    flex-wrap: wrap;
    max-width: 360px;
  }
  @media (max-width: 580px) {
    max-width: 320px;
  }
  @media (max-width: 470px) {
    max-width: 220px;
  }
  @media (max-width: 420px) {
    max-width: 160px;
  }
`

const ListItem = styled.li`
  margin-left: 36px;
  font-size: 14px;
  /* letter-spacing: 3.4px; */
  @media (max-width: 760px) {
    margin-bottom: 4px;
  }
  :hover {
    color: black;
  }
`

const SocialLink = styled.a`
  margin-left: 16px;
`

const FacebookImage = styled.img`
  height: 20px;
`

class Footer extends Component {
  render() {
    return (
      <Container className="footer">
        <div>
          <Link to="/">
            <Logo src={logo} alt="Weingut Göschl - Logo" />
          </Link>
        </div>
        <List>
          <ListItem>
            <SocialLink href="https://www.facebook.com/weingutgoeschl/" target="_blank" rel="noopener noreferrer">
              <FacebookImage src={facebook} alt="Facebook" />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/weingut_goeschl/" target="_blank" rel="noopener noreferrer">
              <FacebookImage src={instagram} alt="Instagram" />
            </SocialLink>
          </ListItem>
          <ListItem>
            <Link to="/Datenschutzerklaerung/">DATENSCHUTZ&shy;ERKLÄRUNG</Link>
          </ListItem>
          <ListItem>
            <Link to="/AGB/">AGB</Link>
          </ListItem>
          <ListItem>
            <Link to="/Versand/">VERSAND</Link>
          </ListItem>
          <ListItem>
            <Link to="/Impressum/">IMPRESSUM</Link>
          </ListItem>
        </List>
      </Container>
    )
  }
}

export default Footer
