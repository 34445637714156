import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'
import slugify from 'slugify'

import logo from '../images/weingut-goeschl-logo.png'
import cart from '../images/cart.svg'

import navbarArrow from '../images/navbar-arrow.svg'

const Container = styled.div`
  position: fixed;
  width: 100%;
  background-color: transparent;
  top: 0;
  z-index: 1000;
  transition: 0.08s;
  &.navbar-not-at-top {
    background-color: white;
    box-shadow: 0 4px 28px -2px rgba(0, 0, 0, 0.06);
  }
`

const List = styled.ul`
  display: flex;
  position: relative;
  margin-top: 42px;
  justify-content: center;
  width: 100%;
  max-width: ${p => p.theme.maxPageWidth};
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  margin-left: auto;
  margin-right: auto;
`

const SubMenu = styled.ul`
  position: absolute;
  overflow: hidden;
  display: none;
  padding: 26px 26px;
  top: 40px;
  left: -26px;
  border-radius: 6px;
  background-color: white;
  /* box-shadow: 0 4px 40px 0 #e8e8e89e; */
  box-shadow: 0 16px 20px 0 rgba(100, 100, 100, 0.3);
  /* box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3); */
`

const SubMenuListItem = styled.li`
  color: ${p => p.theme.textColor};
  margin-bottom: ${p => (p.withSeperator ? '11px' : '22px')};
  border-bottom: ${p => (p.withSeperator ? `1px solid ${p.theme.lightGray}` : '')};
  padding-bottom: ${p => (p.withSeperator ? '11px' : '')};
  :last-child {
    margin-bottom: 0;
  }
  :hover {
    color: black;
  }
`

const ArrowContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  height: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: -1;
`

const Arrow = styled.div`
  position: absolute;
  top: 30px;
  margin-left: auto;
  width: 20px;
  transform: translateY(-50%) rotate(45deg);
  height: 20px;
  background-color: white;
  box-shadow: 0 -4px 20px 0 #e8e8e89e;
  display: none;
`

const ListItem = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 14px;
  letter-spacing: 2.4px;
  padding-bottom: 12px;
  :nth-child(n + 3) {
    margin-left: 50px;
  }
  :nth-child(n + 2) {
    @media (max-width: 1000px) {
      display: none;
    }
  }
  :nth-child(1) {
    position: absolute;
    left: ${p => p.theme.paddingOnSides};
    bottom: 0;
  }
  :nth-child(7) {
    position: absolute;
    display: block;
    right: ${p => p.theme.paddingOnSides};
    bottom: 0;
    @media (max-width: 1000px) {
      right: 100px;
    }
  }
  :hover ${SubMenu} {
    display: block;
  }
  :hover ${Arrow} {
    display: block;
  }
  :focus ${SubMenu}, :focus-within ${SubMenu} {
    display: block;
    outline: none;
  }
  :focus ${Arrow}, :focus-within ${Arrow} {
    display: block;
    outline: none;
  }
  :focus {
    outline: none;
  }
  :hover {
    color: black;
  }
`

const Logo = styled.img`
  width: 120px;
  height: auto;
`

const Cart = styled.img`
  width: 28px;
  @media (max-width: 1000px) {
    width: 32px;
  }
`

const BurgerMenu = styled.li`
  height: 24px;
  margin-bottom: auto;
  margin-left: auto;
  cursor: pointer;
  /* z-index: 3; */
  margin-bottom: 9px;
  transform: translateY(-6px);
  @media (min-width: 1001px) {
    display: none;
  }
`

const BurgerMenuIcon = styled.span`
  display: block;
  width: 26px;
  height: 3px;
  background-color: ${p => p.theme.textColor};
  border-radius: 10px;
  transform: translateY(10px);
  transition: 0.1s;
  background-color: ${({ mobileNavIsHidden }) => !mobileNavIsHidden && 'transparent'};
  &:before {
    content: '';
    display: block;
    width: 26px;
    height: 3px;
    background-color: ${p => p.theme.textColor};
    transform: translateY(-8px);
    border-radius: 10px;
    transition: 0.15s ease-out;
    background-color: ${p => !p.mobileNavIsHidden && p.theme.primaryColor};
    transform: ${({ mobileNavIsHidden }) => !mobileNavIsHidden && 'translateY(0px) rotate(45deg)'};
  }
  &:after {
    content: '';
    display: block;
    width: 26px;
    height: 3px;
    background-color: ${p => p.theme.textColor};
    transform: translateY(5px);
    border-radius: 10px;
    transition: 0.15s ease-out;
    background-color: ${p => !p.mobileNavIsHidden && p.theme.primaryColor};
    transform: ${({ mobileNavIsHidden }) => !mobileNavIsHidden && 'translateY(-3px) rotate(-45deg)'};
  }
`

const CartCircle = styled.div`
  position: absolute;
  top: -12px;
  left: 12px;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  background-color: ${p => p.theme.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
`

const CartCircleNumber = styled.div`
  /* margin: auto; */
  color: white;
  line-height: 1;
  letter-spacing: normal;
  padding-bottom: 1px;
`

class Navbar extends Component {
  removeFocus = e => {
    e.currentTarget.blur()
  }

  render() {
    const { toggleMobileNav, mobileNavIsHidden, openCart, checkout } = this.props
    let lineItemsCount = 0
    if (checkout) {
      lineItemsCount = checkout.lineItems.edges.reduce((count, lineItem) => {
        return count + lineItem.node.quantity
      }, 0)
    }
    return (
      <Container className="navbar">
        <List>
          <ListItem>
            <Link to="/">
              <Logo src={logo} alt="Logo - Weingut Göschl" />
            </Link>
          </ListItem>
          <ListItem tabIndex="0" onClick={this.removeFocus}>
            <div style={{ cursor: 'pointer' }}>
              DAS WEINGUT <img style={{ transform: 'translate(0,-1px)' }} src={navbarArrow} alt="" />
            </div>
            <ArrowContainer>
              <Arrow />
            </ArrowContainer>
            <SubMenu style={{ minWidth: '200px' }}>
              <SubMenuListItem>
                <Link onClick={this.removeFocus} to="/Weingut/">
                  ÜBER UNS
                </Link>
              </SubMenuListItem>
              <SubMenuListItem>
                <Link onClick={this.removeFocus} to="/Lagen/">
                  LAGEN
                </Link>
              </SubMenuListItem>
              <SubMenuListItem>
                <Link onClick={this.removeFocus} to="/Presse/">
                  PRESSE / FOTOS
                </Link>
              </SubMenuListItem>
            </SubMenu>
          </ListItem>
          <ListItem tabIndex="0" onClick={this.removeFocus}>
            <div style={{ cursor: 'pointer' }}>
              WEINE <img style={{ transform: 'translate(0,-1px)' }} src={navbarArrow} alt="" />
            </div>
            <ArrowContainer>
              <Arrow />
            </ArrowContainer>
            <SubMenu style={{ minWidth: '200px' }}>
              <SubMenuListItem withSeperator>
                <Link onClick={this.removeFocus} to="/Weine/">
                  ALLE WEINE
                </Link>
              </SubMenuListItem>
              <StaticQuery
                query={graphql`
                  query {
                    wineCollections: allMyStoreShopifyCollection(sort: { fields: description }) {
                      edges {
                        node {
                          id
                          title
                          products {
                            id
                          }
                        }
                      }
                    }
                  }
                `}
                render={data => {
                  return data.wineCollections.edges.map(
                    ({ node }) =>
                      node.products.length != 0 && (
                        <SubMenuListItem key={node.id}>
                          <Link onClick={this.removeFocus} to={`/Weine/#${slugify(node.title).toUpperCase()}`}>
                            {node.title}
                          </Link>
                        </SubMenuListItem>
                      )
                  )
                }}
              />
            </SubMenu>
          </ListItem>
          <ListItem tabIndex="0" onClick={this.removeFocus}>
            <div style={{ cursor: 'pointer' }}>
              YOGA <img style={{ transform: 'translate(0,-1px)' }} src={navbarArrow} alt="" />
            </div>
            <ArrowContainer>
              <Arrow />
            </ArrowContainer>
            <SubMenu style={{ minWidth: '216px' }}>
              <SubMenuListItem>
                <Link onClick={this.removeFocus} to="/Yoga-und-Wein/">
                  YOGA & WEIN
                </Link>
              </SubMenuListItem>
              <SubMenuListItem>
                <Link onClick={this.removeFocus} to="/Yogaraum-Gols/">
                  YOGARAUM GOLS
                </Link>
              </SubMenuListItem>
              <SubMenuListItem>
                <Link onClick={this.removeFocus} to="/Yoga-und-Wein-Retreats/">
                  YOGA & WEIN RETREATS
                </Link>
              </SubMenuListItem>
            </SubMenu>
          </ListItem>
          <ListItem tabIndex="0" onClick={this.removeFocus}>
            <div style={{ cursor: 'pointer' }}>
              AKTUELLES <img style={{ transform: 'translate(0,-1px)' }} src={navbarArrow} alt="" />
            </div>
            <ArrowContainer>
              <Arrow />
            </ArrowContainer>
            <SubMenu style={{ minWidth: '216px' }}>
              <SubMenuListItem>
                <Link onClick={this.removeFocus} to="/Neuigkeiten/">
                  NEUIGKEITEN
                </Link>
              </SubMenuListItem>
              <SubMenuListItem>
                <Link onClick={this.removeFocus} to="/Termine/">
                  TERMINE / EVENTS
                </Link>
              </SubMenuListItem>
            </SubMenu>
          </ListItem>
          <ListItem>
            <Link to="/Kontakt/">KONTAKT</Link>
          </ListItem>
          <ListItem>
            <button onClick={openCart}>
              <Cart src={cart} alt="Einkaufswagen" />
              {lineItemsCount >= 1 && (
                <CartCircle>
                  <CartCircleNumber>{lineItemsCount}</CartCircleNumber>
                </CartCircle>
              )}
            </button>
          </ListItem>
          <BurgerMenu onClick={toggleMobileNav}>
            <BurgerMenuIcon mobileNavIsHidden={mobileNavIsHidden} />
          </BurgerMenu>
        </List>
      </Container>
    )
  }
}

export default Navbar
