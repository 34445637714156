import React, { Component } from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { formatCartPrice } from '../utilities/formatCartPrice'
import { Link } from 'gatsby'
import slugify from 'slugify'

import remove from '../images/remove.svg'

slugify.extend({ ä: 'ae' })
slugify.extend({ ö: 'oe' })
slugify.extend({ ü: 'ue' })
slugify.extend({ Ä: 'AE' })
slugify.extend({ Ö: 'OE' })
slugify.extend({ Ü: 'UE' })
slugify.extend({ ',': '.' })

const Container = styled.div`
  display: flex;
  margin-top: 32px;
`

const CartItemLeft = styled.div`
  width: 75px;
  height: 106px;
  img {
    width: 100%;
  }
`

const CartItemMid = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 164px; */
  width: 224px;
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 6px;
  @media (max-width: 500px) {
    width: 180px;
  }
  @media (max-width: 410px) {
    width: 160px;
  }
`

const CartItemHeading = styled.h2`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: ${p => p.theme.textColorDark};
  font-weight: 600;
`
const CartItemSubHeading = styled.p`
  font-size: 14px;
  color: ${p => p.theme.textColorDark};
  font-weight: 300;
`

const CartItemMidBottom = styled.div`
  display: flex;
  margin-top: auto;
`

const CartItemRight = styled.p`
  align-self: flex-end;
  /* margin-bottom: 6px; */
  margin-left: auto;
  margin-top: auto;
  font-size: 16px;
  color: ${p => p.theme.textColorDark};
  font-weight: 600;
  margin-bottom: 3px;
`

const CartItemEnd = styled.button`
  align-self: flex-end;
  margin-left: auto;
  font-size: 16px;
  font-weight: 700;
  color: #e38888;
  line-height: 1;
  margin-bottom: 9px;
`

const RemoveIcon = styled.img`
  width: 10px;
  transform: translateY(-5px);
  @media (max-width: 410px) {
    transform: translateY(-4px);
  }
`

const AnzahlContainer = styled.div`
  display: flex;
  margin-top: auto;
`

const AnzahlOperatorPlus = styled.span`
  /* margin: auto; */
  line-height: 1;
  padding-bottom: 1px;
  color: ${p => p.theme.textColor};
`

const AnzahlOperatorMinus = styled.span`
  /* margin: auto; */
  line-height: 1;
  padding-bottom: 3px;
  color: ${p => p.theme.textColor};
`

const AnzahlButton = styled.button`
  width: 22px;
  height: 22px;
  border-radius: 32px;
  border: 1px solid ${p => p.theme.midGray};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.midGray};
  font-size: 16px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  transition: 0.12s ease-out;
  user-select: none;
  :hover {
    background-color: ${p => p.theme.textColorDark};
    border-color: ${p => p.theme.textColorDark};
  }
  :hover ${AnzahlOperatorPlus}, :hover ${AnzahlOperatorMinus} {
    color: white;
  }
  margin-top: 1px;
  @media (max-width: 410px) {
    width: 20px;
    height: 20px;
    font-size: 14px;
    margin-top: 2px;
  }
`

const Anzahl = styled.div`
  margin-left: 14px;
  margin-right: 14px;
  font-size: 16px;
  margin-bottom: 3px;
`

class CartItem extends Component {
  decrementQuantity = (lineItemId, checkoutNumber) => {
    this.props.updateLineItemInCart(lineItemId, this.props.quantity - 1, checkoutNumber)
  }

  incrementQuantity = (lineItemId, checkoutNumber) => {
    this.props.updateLineItemInCart(lineItemId, this.props.quantity + 1, checkoutNumber)
  }

  render() {
    const { id, name, year, classification, price, quantity, image, removeLineItemInCart } = this.props
    let titlePlusYear = name
    if (year.length > 0) {
      titlePlusYear = `${name}-${year}`
    }
    let titlePlusYearPlusClassification = titlePlusYear
    if (classification !== '') {
      titlePlusYearPlusClassification = `${titlePlusYear}-${classification}`
    }

    const slug = slugify(titlePlusYearPlusClassification)
    return (
      <Container>
        <StaticQuery
          query={graphql`
            query CheckoutNumberQuery {
              site {
                siteMetadata {
                  checkoutNumber
                }
              }
            }
          `}
          render={data => (
            <React.Fragment>
              <CartItemLeft>
                <Link to={`/${slug}`}>
                  {image && <img src={image.src} alt={name} style={{ background: 'white', color: 'white' }} />}
                </Link>
              </CartItemLeft>
              <CartItemMid>
                <CartItemHeading>
                  <Link to={`/${slug}`}>{name}</Link>
                </CartItemHeading>
                <CartItemSubHeading>
                  {year}
                  {classification && (
                    <span>
                      <span style={{ fontSize: '12px' }}> | </span> {classification}
                    </span>
                  )}
                </CartItemSubHeading>
                <CartItemMidBottom>
                  <AnzahlContainer>
                    <AnzahlButton onClick={() => this.decrementQuantity(id, data.site.siteMetadata.checkoutNumber)}>
                      <AnzahlOperatorMinus>&ndash;</AnzahlOperatorMinus>
                    </AnzahlButton>
                    <Anzahl>{quantity}</Anzahl>
                    <AnzahlButton onClick={() => this.incrementQuantity(id, data.site.siteMetadata.checkoutNumber)}>
                      <AnzahlOperatorPlus>+</AnzahlOperatorPlus>
                    </AnzahlButton>
                  </AnzahlContainer>
                  <CartItemRight>{formatCartPrice(price)}</CartItemRight>
                </CartItemMidBottom>
              </CartItemMid>
              <CartItemEnd onClick={() => removeLineItemInCart(id, data.site.siteMetadata.checkoutNumber)}>
                <RemoveIcon src={remove} alt="Produkt entfernen" />
              </CartItemEnd>
            </React.Fragment>
          )}
        />
      </Container>
    )
  }
}

export default CartItem
