import styled from 'styled-components'

const Button = styled.button`
  background-color: ${p => p.theme.primaryColor};
  padding: 10px 20px;
  color: white;
  border-radius: 6px;
  letter-spacing: 2.2px;
  line-height: 1.4;
  width: ${p => (p.fullWidth ? '100%' : '')};
  transition: opacity 0.15s ease-out;
  :hover {
    opacity: 0.84;
  }
`

const WeinPageButton = styled(Button)`
  padding: 12px 91px;
  @media (max-width: 444px) {
    padding: 12px 20px;
    width: 100%;
  }
  :disabled, :disabled:hover {
    opacity: .5;
    cursor: auto;
  }
  /* ${p => p.cartIsLoading && 'opacity: .5 !important;'} */
`

const DarkWeinPageButton = styled(Button)`
  padding: 12px 41px;
  background-color: ${p => p.theme.textColor};
  margin-top: -10px;
  @media (max-width: 444px) {
    padding: 12px 20px;
    width: 100%;
  }
`

export default Button

export { WeinPageButton, DarkWeinPageButton }
