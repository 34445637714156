import React from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { Link, graphql, StaticQuery } from 'gatsby'
import slugify from 'slugify'
import { animated } from 'react-spring'

import StyledLink from './styles/StyledLink'

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: white;
  z-index: 2000;
  /* transition: all 0.15s; */
  /* transition: all 0.15s; */
`

const Text = styled.div`
  padding-top: 80px;
  padding-left: 15vw;
`

const ListContainer = styled.ul`
  padding-bottom: 30px;
`

const SubListContainer = styled.ul`
  padding-left: 22px;
  margin-top: 10px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const TopListItem = styled.li`
  color: ${p => p.theme.textColorDark};
  font-size: 15px;
  letter-spacing: 2.4px;
  font-weight: 600;
  @media (max-width: 400px) {
    font-size: 13px;
  }
`

const SubListItem = styled.li`
  display: inline-block;
  font-size: 30px;
  line-height: 54px;
  font-weight: 300;
  color: ${p => p.theme.textColor};
  @media (max-width: 400px) {
    font-size: 27px;
    line-height: 48px;
    ${p => p.small && `line-height: 1;`}
  }
  @media (max-width: 350px) {
    font-size: 24px;
    line-height: 42px;
    ${p => p.small && `line-height: 1;`}
  }
  border-bottom: ${p => (p.withSeperator ? `1px solid ${p.theme.lightGray}` : '')};
  ${p => p.small && `line-height: 1;`}
`

const Divider = styled.div`
  width: 64px;
  height: 2px;
  background-color: ${p => p.theme.primaryColorLight};
  margin-top: 14px;
  margin-bottom: 26px;
`

const BurgerMenu = styled.div`
  position: absolute;
  top: 42px;
  right: 30px;
  width: 30px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
  transform: translateY(-6px);
`

const BurgerMenuIcon = styled.span`
  display: block;
  width: 26px;
  height: 3px;
  background-color: white;
  border-radius: 10px;
  transform: translateY(10px);
  transition: 0.1s;
  /* background-color: ${({ mobileNavIsHidden }) => mobileNavIsHidden && 'transparent'}; */

  &.mobilenav-icon-animation-enter-active {
    background-color: transparent;
  }
  &.mobilenav-icon-animation-enter-active:before {
    background-color: ${p => p.theme.primaryColor};
    transform: translateY(0px) rotate(45deg);
  }
  &.mobilenav-icon-animation-enter-active:after {
    background-color: ${p => p.theme.primaryColor};
    transform: translateY(-3px) rotate(-45deg);
  }
  &.mobilenav-icon-animation-enter-done {
    background-color: transparent;
  }
  &.mobilenav-icon-animation-enter-done:before {
    background-color: ${p => p.theme.primaryColor};
    transform: translateY(0px) rotate(45deg);
  }
  &.mobilenav-icon-animation-enter-done:after {
    background-color: ${p => p.theme.primaryColor};
    transform: translateY(-3px) rotate(-45deg);
  }
  &:before {
    content: '';
    display: block;
    width: 26px;
    height: 3px;
    background-color: white;
    transform: translateY(-8px);
    border-radius: 10px;
    transition: 0.15s ease-out;
  }
  &:after {
    content: '';
    display: block;
    width: 26px;
    height: 3px;
    background-color: white;
    transform: translateY(5px);
    border-radius: 10px;
    transition: 0.15s ease-out;
  }
`

const AdditionalText = styled.div`
  font-size: 13px;
  line-height: 20px;
  padding-bottom: 20px;
`

class MobileNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
    }
    this.handleCloseButton = this.handleCloseButton.bind(this)
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    })
  }

  handleCloseButton() {
    this.setState(
      {
        mounted: false,
      },
      () => this.props.toggleMobileNav()
    )
  }

  render() {
    const { style, toggleMobileNav } = this.props
    // console.dir(style.myStyle)
    return (
      <Container style={style}>
        <Text>
          <ListContainer>
            <TopListItem>
              DAS WEINGUT
              <SubListContainer>
                <SubListItem>
                  <Link to="/Weingut/" onClick={toggleMobileNav}>
                    Über Uns
                  </Link>
                </SubListItem>
                <SubListItem>
                  <Link to="/Lagen/" onClick={toggleMobileNav}>
                    Lagen
                  </Link>
                </SubListItem>
                <SubListItem>
                  <Link to="/Presse/" onClick={toggleMobileNav}>
                    Presse / Fotos
                  </Link>
                </SubListItem>
              </SubListContainer>
            </TopListItem>
            <TopListItem>
              WEINE
              <SubListContainer>
                <SubListItem withSeperator>
                  <Link to="/Weine/" onClick={toggleMobileNav}>
                    Alle Weine
                  </Link>
                </SubListItem>
                <StaticQuery
                  query={graphql`
                    query {
                      wineCollections: allMyStoreShopifyCollection(sort: { fields: description }) {
                        edges {
                          node {
                            id
                            title
                            products {
                              id
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={data => {
                    return data.wineCollections.edges.map(
                      ({ node }) =>
                        node.products.length != 0 && (
                          <SubListItem key={node.id}>
                            <Link to={`/Weine/#${slugify(node.title).toUpperCase()}`} onClick={toggleMobileNav}>
                              {titleCase(node.title)}
                            </Link>
                          </SubListItem>
                        )
                    )
                  }}
                />
              </SubListContainer>
            </TopListItem>
            <TopListItem>
              YOGA
              <SubListContainer>
                <SubListItem>
                  <Link to="/Yoga-und-Wein/" onClick={toggleMobileNav}>
                    Yoga & Wein
                  </Link>
                </SubListItem>
                <SubListItem>
                  <Link to="/Yogaraum-Gols/" onClick={toggleMobileNav}>
                    YogaRaum Gols
                  </Link>
                </SubListItem>
                <SubListItem>
                  <Link to="/Yoga-und-Wein-Retreats/" onClick={toggleMobileNav}>
                    Yoga & Wein Retreats
                  </Link>
                </SubListItem>
              </SubListContainer>
            </TopListItem>
            <TopListItem>
              AKTUELLES
              <SubListContainer>
                <SubListItem>
                  <Link to="/Neuigkeiten/" onClick={toggleMobileNav}>
                    Neuigkeiten
                  </Link>
                </SubListItem>
                <SubListItem>
                  <Link to="/Termine/" onClick={toggleMobileNav}>
                    Termine / Events
                  </Link>
                </SubListItem>
              </SubListContainer>
            </TopListItem>
            <SubListItem small>
              <Link to="/Kontakt/" onClick={toggleMobileNav}>
                Kontakt
              </Link>
            </SubListItem>
          </ListContainer>
          <Divider />
          <AdditionalText>
            <span style={{ fontWeight: '600' }}>Weingut Göschl</span>
            <br />
            Tel:{' '}
            <StyledLink as="a" href="tel:+436644530988">
              +43 664 45 30 988
            </StyledLink>
            <br /> E-Mail:{' '}
            <StyledLink as="a" href="mailto:office@weingut-goeschl.at">
              office@weingut-goeschl.at
            </StyledLink>
          </AdditionalText>
        </Text>
        <BurgerMenu onClick={this.props.toggleMobileNav}>
          <CSSTransition timeout={0} classNames="mobilenav-icon-animation" in={this.state.mounted} unmountOnExit>
            <BurgerMenuIcon mobileNavIsHidden={this.props.mobileNavIsHidden} />
          </CSSTransition>
        </BurgerMenu>
      </Container>
    )
  }
}
export default MobileNav
