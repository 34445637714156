import './src/components/styles/modern-normalize.css'
import 'focus-visible'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { client } from './src/apollo/client'
// import Store from './src/components/Store'
import StoreContainer from './src/components/StoreContainer'

import './src/styles/fonts.css'

export const wrapRootElement = ({ element }) => <ApolloProvider client={client}>{element}</ApolloProvider>

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <StoreContainer {...props}>{element}</StoreContainer>
}

export const onClientEntry = () => {}
