const theme = {
  maxPageWidth: '1260px',
  paddingOnSides: '35px',
  // marginToTop: '169px',
  marginToTop: '140px',
  primaryColor: '#EC7A5C',
  primaryColorLight: '#FADAD1',
  primaryColorLighter: '#ffe8e2',
  textColor: '#484848',
  textColorDark: '#2B2B2B',
  lightGray: '#ECECEC',
  midGray: '#D1D1D1',
  midDarkGray: '#989898',
}

export default theme
