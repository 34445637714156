// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/AGB.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/Datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/Impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/Kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lagen-js": () => import("./../../../src/pages/Lagen.js" /* webpackChunkName: "component---src-pages-lagen-js" */),
  "component---src-pages-neuigkeiten-js": () => import("./../../../src/pages/Neuigkeiten.js" /* webpackChunkName: "component---src-pages-neuigkeiten-js" */),
  "component---src-pages-presse-js": () => import("./../../../src/pages/Presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-pages-termine-js": () => import("./../../../src/pages/Termine.js" /* webpackChunkName: "component---src-pages-termine-js" */),
  "component---src-pages-versand-js": () => import("./../../../src/pages/Versand.js" /* webpackChunkName: "component---src-pages-versand-js" */),
  "component---src-pages-weine-js": () => import("./../../../src/pages/Weine.js" /* webpackChunkName: "component---src-pages-weine-js" */),
  "component---src-pages-weingut-js": () => import("./../../../src/pages/Weingut.js" /* webpackChunkName: "component---src-pages-weingut-js" */),
  "component---src-pages-yoga-und-wein-js": () => import("./../../../src/pages/Yoga-und-Wein.js" /* webpackChunkName: "component---src-pages-yoga-und-wein-js" */),
  "component---src-pages-yoga-und-wein-retreats-js": () => import("./../../../src/pages/Yoga-und-Wein-Retreats.js" /* webpackChunkName: "component---src-pages-yoga-und-wein-retreats-js" */),
  "component---src-pages-yogaraum-gols-js": () => import("./../../../src/pages/Yogaraum-Gols.js" /* webpackChunkName: "component---src-pages-yogaraum-gols-js" */),
  "component---src-templates-wein-js": () => import("./../../../src/templates/Wein.js" /* webpackChunkName: "component---src-templates-wein-js" */)
}

