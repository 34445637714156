import styled from 'styled-components'

const StyledLink = styled.span`
position: relative;
z-index: 2;
:after {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  bottom: 1px;
  background-color: ${p => p.theme.primaryColorLighter};
  width: 100%;
  height: 6px;
  z-index: -1;
  /* opacity: ${props => (props.alwaysOn ? '1' : '0')}; */
}
:hover:after {
  /* opacity: 1; */
  /* height: 20px; */
}
`

export default StyledLink
