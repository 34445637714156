import React from 'react'
import Helmet from 'react-helmet'

const HelmetInfo = () => {
  return (
    <Helmet>
      <html lang="de" />
      <meta name="author" content="Peter Kroyer (https://www.peterkroyer.com/)" />

      <title>Weingut Göschl & Töchter</title>
      <meta property="og:title" content="Weingut Göschl & Töchter" />
      <meta name="description" content="Weingut Göschl & Töchter" />
      <meta property="og:description" content="Weingut Göschl & Töchter" />
      <meta property="og:type" content="website" />

      {/* Favicons */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  )
}

export default HelmetInfo
