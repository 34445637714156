export function formatCartPrice(price) {
  if (price === undefined) {
    return undefined
  }
  if (typeof price === Number) {
    String(price)
  }
  const priceWithComma = price.replace(/\./, ',')
  const priceWithCommaAndCurrency = `${priceWithComma} €`
  return priceWithCommaAndCurrency
}
