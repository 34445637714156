import React, { Component } from 'react'

import {
  createCheckout,
  checkoutCompleted,
  checkoutLineItemsAdd,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
  addVariantToCart,
  updateLineItemInCart,
  removeLineItemInCart,
} from '../apollo/checkout'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'

import Layout from './Layout'

class Store extends Component {
  constructor() {
    super()

    this.state = {
      // isCartOpen: false,
      // isCustomerAuthOpen: false,
      // isNewCustomer: false,
      products: [],
      checkout: { lineItems: { edges: [] } },
      cartIsHidden: true,
      cartIsLoading: false,
    }

    this.addVariantToCart = addVariantToCart.bind(this)
    this.removeLineItemInCart = removeLineItemInCart.bind(this)
    this.updateLineItemInCart = updateLineItemInCart.bind(this)
  }

  UNSAFE_componentWillMount() {
    const checkoutNumber = this.props.checkoutNumber

    if (typeof window !== 'undefined' && window.localStorage) {
      if (localStorage) {
        const cachedCheckout = localStorage.getItem(checkoutNumber)
        if (cachedCheckout && JSON.parse(cachedCheckout) != null) {
          this.setState({ checkout: JSON.parse(cachedCheckout) })
        } else {
          localStorage.clear()
          this.props
            .createCheckout({
              variables: {
                input: {},
              },
            })
            .then(res => {
              this.setState({
                checkout: res.data.checkoutCreate.checkout,
              })
            })
        }
      } else {
        this.props
          .createCheckout({
            variables: {
              input: {},
            },
          })
          .then(res => {
            this.setState({
              checkout: res.data.checkoutCreate.checkout,
            })
          })
      }
    }
  }

  componentDidMount() {
    if (this.state.checkout && this.state.checkout.id) {
      this.props.client
        .query({
          query: checkoutCompleted,
          variables: {
            checkoutId: this.state.checkout.id,
          },
        })
        .then(res => {
          if (res.data.node.completedAt) {
            localStorage.clear()
            this.props
              .createCheckout({
                variables: {
                  input: {},
                },
              })
              .then(res => {
                this.setState({
                  checkout: res.data.checkoutCreate.checkout,
                })
              })
          }
        })
    }
  }

  // toggleCart = () => {
  //   this.setState((prevState) => {
  //     return { cartIsHidden: !prevState.cartIsHidden }
  //   })
  // }

  openCart = () => {
    this.setState({ cartIsHidden: false })
  }
  closeCart = () => {
    this.setState({ cartIsHidden: true })
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        addVariantToCart: this.addVariantToCart,
        checkout: this.state.checkout,
        cartIsLoading: this.state.cartIsLoading,
      })
    })
    return (
      <Layout
        updateLineItemInCart={this.updateLineItemInCart}
        removeLineItemInCart={this.removeLineItemInCart}
        checkout={this.state.checkout}
        openCart={this.openCart}
        closeCart={this.closeCart}
        cartIsHidden={this.state.cartIsHidden}
        cartIsLoading={this.state.cartIsLoading}
      >
        {children}
      </Layout>
    )
  }
}

// export default Store

const StoreWithDataAndMutation = compose(
  // graphql(query),
  withApollo,
  // graphql(checkoutCompleted, { name: 'checkoutCompleted' }),
  graphql(createCheckout, { name: 'createCheckout' }),
  graphql(checkoutLineItemsAdd, { name: 'checkoutLineItemsAdd' }),
  graphql(checkoutLineItemsUpdate, { name: 'checkoutLineItemsUpdate' }),
  graphql(checkoutLineItemsRemove, { name: 'checkoutLineItemsRemove' })
  // graphql(checkoutCustomerAssociate, {name: "checkoutCustomerAssociate"})
)(Store)

export default StoreWithDataAndMutation
