import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { animated } from 'react-spring'

import { formatCartPrice } from '../utilities/formatCartPrice'

import Button from './styles/Button'
import CartItem from './CartItem'
import StyledLink from './styles/StyledLink'

import close from '../images/close.svg'
import emptyCart from '../images/empty-cart.svg'
import backgroundWatercolor from '../images/photos/background-watercolor.png'
import warningIcon from '../images/warning-icon.svg'

const Container = styled(animated.div)`
  height: 100vh;
  /* height: 100%; */
  width: 415px;
  position: fixed;
  top: 0;
  /* bottom: 0; */
  right: 0;
  background-color: white;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  box-shadow: 0 4px 28px -2px rgba(0, 0, 0, 0.06);
  overflow-y: auto;
  @media (max-width: 500px) {
    width: 375px;
  }
  @media (max-width: 410px) {
    width: 330px;
  }
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px 44px 15px 36px;
  border-bottom: 1px solid ${p => p.theme.lightGray};
  @media (max-width: 410px) {
    padding: 27px 44px 18px 36px;
  }
`

const Heading = styled.h1`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: ${p => p.theme.textColorDark};
  @media (max-width: 410px) {
    font-size: 20px;
  }
`

const CloseButton = styled.button``

const Close = styled.img`
  @media (max-width: 410px) {
    width: 16px;
  }
`

const CartItemContainer = styled.div`
  padding-left: 18px;
  padding-right: 44px;
  padding-bottom: 30px;
`

const Top = styled.div`
  margin-bottom: auto;
`
const Middle = styled.div`
  margin: 0px 44px 14px 44px;
`

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 15px;
  background-color: #fff4f2;
  border-radius: 50px;
  font-size: 13px;
  line-height: 1.3;
  color: #52312c;
  flex-shrink: 0;
`

const WarningIcon = styled.img`
  margin-right: 14px;
  min-width: 25px;
  min-height: 25px;
`

const Bottom = styled.div`
  border-top: 1px solid ${p => p.theme.lightGray};
  padding: 30px 44px 14px 44px;
`

const SubTotal = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    margin-bottom: 16px;
  }
`

const SubTotalLeft = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
`

const SubTotalRight = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
`

const Paragraph = styled.p`
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
`

const SmallFont = styled.span`
  font-size: 13px;
  @media (max-width: 500px) {
    font-size: 12.5px;
  }
`

const EmptyCartContainer = styled.div`
  position: relative;
  margin-top: 100px;
  text-align: center;
`

const EmptyCartImage = styled.img`
  display: block;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 60px;
  opacity: 0.9;
`

const WaterColor = styled.img`
  position: absolute;
  width: 250px;
  opacity: 0.2;
  z-index: -1;
  top: -40px;
  left: 50px;
  @media (max-width: 500px) {
    left: 30px;
  }
  @media (max-width: 410px) {
    left: 5px;
  }
`

const White = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  opacity: 0.4;
`

const Below = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`

const Above = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`

class Cart extends Component {
  // constructor(props) {
  //   super(props)
  //   this.ContainerRef = React.createRef()
  // }

  openCheckout = lineItemsWeight => {
    const { checkout } = this.props
    if (checkout.lineItems.edges.length >= 1 && lineItemsWeight >= 7) {
      // window.open(this.props.checkout.webUrl)
      window.location.href = this.props.checkout.webUrl
    }
  }

  // componentDidMount() {
  //   let vh = window.innerHeight * 0.01
  //   this.ContainerRef.current.style.setProperty('--vh', `${vh}px`)
  //   window.addEventListener('resize', () => {
  //     let vh = window.innerHeight * 0.01
  //     this.ContainerRef.current.style.setProperty('--vh', `${vh}px`)
  //   })
  //   window.addEventListener('touchmove', () => {
  //     let vh = window.innerHeight * 0.01
  //     this.ContainerRef.current.style.setProperty('--vh', `${vh}px`)
  //   })
  // }

  render() {
    const { closeCart, style, checkout, removeLineItemInCart, updateLineItemInCart, cartIsLoading } = this.props
    let checkoutLineItems = []
    // let lineItemsCount = undefined
    let lineItemsWeight = undefined
    if (checkout && checkout.lineItems && checkout.lineItems.edges) {
      checkoutLineItems = checkout.lineItems.edges
      if (checkout.lineItems.edges.length > 0) {
        // lineItemsCount = checkout.lineItems.edges.reduce((count, lineItem) => {
        //   return count + lineItem.node.quantity
        // }, 0)
        lineItemsWeight = checkout.lineItems.edges.reduce((weight, lineItem) => {
          if (lineItem.node.variant) {
            return weight + lineItem.node.quantity * lineItem.node.variant.weight
          } else {
            return 0
          }
        }, 0)
      }
    }
    let lineItemsCount = undefined
    if (lineItemsWeight) {
      lineItemsCount = Math.round(lineItemsWeight / 1.2)
    }
    return (
      <Container ref={this.ContainerRef} style={style}>
        {cartIsLoading && <White id="White" />}
        <Top>
          <HeadingContainer>
            <Heading>WARENKORB</Heading>
            <CloseButton onClick={closeCart}>
              <Close src={close} alt="Warenkorb schließen" />
            </CloseButton>
          </HeadingContainer>
          <CartItemContainer>
            {/* <CartItem name="Zweigelt - Goldberg" year="2016" price="11,00 €" /> */}
            {/* <CartItem name="Zweigelt - Goldberg" year="2016" price="11,00 €" /> */}
            {/* <CartItem name="Zweigelt - Goldberg" year="2016" price="11,00 €" /> */}
            {checkoutLineItems.length > 0 ? (
              checkoutLineItems.map(lineItem => {
                let year = ''
                if (lineItem && lineItem.node && lineItem.node.variant && lineItem.node.variant.selectedOptions) {
                  let filteredYear = lineItem.node.variant.selectedOptions.filter(option => option.name === 'Jahrgang')
                  if (filteredYear && filteredYear[0] && filteredYear[0].value) {
                    year = filteredYear[0].value
                  }
                }
                let classification = ''
                if (lineItem && lineItem.node && lineItem.node.variant && lineItem.node.variant.selectedOptions) {
                  let filteredClassification = lineItem.node.variant.selectedOptions.filter(
                    option => option.name === 'Klassifizierung'
                  )
                  if (filteredClassification && filteredClassification[0] && filteredClassification[0].value) {
                    classification = filteredClassification[0].value
                  }
                }
                return (
                  <CartItem
                    id={lineItem.node.id}
                    key={lineItem.node.id}
                    name={lineItem.node.title}
                    year={year}
                    classification={classification}
                    quantity={lineItem.node.quantity}
                    price={parseFloat(lineItem.node.variant.price.amount * lineItem.node.quantity).toFixed(2)}
                    image={lineItem.node.variant.image}
                    removeLineItemInCart={removeLineItemInCart}
                    updateLineItemInCart={updateLineItemInCart}
                  />
                )
              })
            ) : (
              <EmptyCartContainer>
                <WaterColor alt="" src={backgroundWatercolor} />
                <EmptyCartImage src={emptyCart} alt="Der Warenkorb ist leer" />
                Ihr Warenkorb ist noch leer.
                <br />
                Hier gehts zum{' '}
                <Link to="/Weine/" onClick={closeCart}>
                  <StyledLink>Webshop</StyledLink>
                </Link>
                .
              </EmptyCartContainer>
            )}
          </CartItemContainer>
        </Top>
        {/* {lineItemsCount && lineItemsCount < 6 && ( */}
        {lineItemsWeight && lineItemsWeight < 7 ? (
          <Middle>
            <Link to="/Versand/">
              <WarningContainer>
                <WarningIcon src={warningIcon} alt="" />
                <div>Die Mindestbestellmenge beträgt 6 Flaschen</div>
              </WarningContainer>
            </Link>
          </Middle>
        ) : (
          lineItemsWeight &&
          ((lineItemsCount > 6 && lineItemsCount < 12) ||
            (lineItemsCount > 12 && lineItemsCount < 18) ||
            (lineItemsCount > 18 && lineItemsCount < 24) ||
            (lineItemsCount > 24 && lineItemsCount < 30) ||
            (lineItemsCount > 30 && lineItemsCount < 36)) && (
            <Middle>
              <Link to="/Versand/">
                <WarningContainer>
                  <WarningIcon src={warningIcon} alt="" />
                  <div>
                    Ihr Karton ist noch nicht voll. Es ist noch Platz für {6 - (lineItemsCount % 6)} Flasche
                    {lineItemsCount % 6 !== 5 && 'n'}.
                  </div>
                </WarningContainer>
              </Link>
            </Middle>
          )
        )}
        <Bottom>
          <SubTotal>
            <SubTotalLeft>Zwischensumme</SubTotalLeft>
            {checkout && <SubTotalRight>{formatCartPrice(checkout.subtotalPrice.amount)}</SubTotalRight>}
          </SubTotal>
          <Above>
            <Paragraph>
              <SmallFont>Die Versandkosten werden beim Checkout berechnet</SmallFont>
            </Paragraph>
          </Above>
          {/* <Button fullWidth onClick={() => this.openCheckout(lineItemsCount)}> */}
          <Button fullWidth onClick={() => this.openCheckout(lineItemsWeight)}>
            ZUR KASSE
          </Button>
          <Below>
            <Paragraph>
              <SmallFont>Die Versandkosten werden beim Checkout berechnet</SmallFont>
            </Paragraph>
          </Below>
        </Bottom>
      </Container>
    )
  }
}

export default Cart
