import gql from 'graphql-tag'

const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    totalTax {
      amount
    }
    subtotalPrice {
      amount
    }
    totalPrice {
      amount
    }
    lineItems(first: 250) {
      edges {
        node {
          id
          title
          variant {
            id
            title
            image {
              src
            }
            price {
              amount
            }
            selectedOptions {
              name
              value
            }
            weight
          }
          quantity
        }
      }
    }
  }
`

export const createCheckout = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`

export const checkoutLineItemsAdd = gql`
  mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`

export const checkoutLineItemsUpdate = gql`
  mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`

export const checkoutLineItemsRemove = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`

export const checkoutCompleted = gql`
  query node($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        completedAt
      }
    }
  }
`

export function addVariantToCart(variantId, quantity, checkoutNumber) {
  this.setState({ cartIsLoading: true })
  this.props
    .checkoutLineItemsAdd({
      variables: { checkoutId: this.state.checkout.id, lineItems: [{ variantId, quantity: parseInt(quantity, 10) }] },
    })
    .then(res => {
      this.setState({ cartIsLoading: false })
      this.setState(
        {
          checkout: res.data.checkoutLineItemsAdd.checkout,
        },
        () => {
          if (localStorage) {
            localStorage.setItem(checkoutNumber, JSON.stringify(this.state.checkout))
          }
        }
      )
    })

  this.openCart()
}

export function updateLineItemInCart(lineItemId, quantity, checkoutNumber) {
  this.setState({ cartIsLoading: true })
  this.props
    .checkoutLineItemsUpdate({
      variables: {
        checkoutId: this.state.checkout.id,
        lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
      },
    })
    .then(res => {
      this.setState({ cartIsLoading: false })
      if (res.data.checkoutLineItemsUpdate.checkout != null) {
        this.setState(
          {
            checkout: res.data.checkoutLineItemsUpdate.checkout,
          },
          () => {
            if (localStorage) {
              localStorage.setItem(checkoutNumber, JSON.stringify(this.state.checkout))
            }
          }
        )
      }
    })
}

export function removeLineItemInCart(lineItemId, checkoutNumber) {
  this.setState({ cartIsLoading: true })
  this.props
    .checkoutLineItemsRemove({ variables: { checkoutId: this.state.checkout.id, lineItemIds: [lineItemId] } })
    .then(res => {
      this.setState({ cartIsLoading: false })
      if (res.data.checkoutLineItemsRemove.checkout != null) {
        this.setState(
          {
            checkout: res.data.checkoutLineItemsRemove.checkout,
          },
          () => {
            if (localStorage) {
              localStorage.setItem(checkoutNumber, JSON.stringify(this.state.checkout))
            }
          }
        )
      }
    })
}
